<template>
  <section
    ref="dashboard"
    class="EmailVerifiedPage"
  >
    <one-column>
      <template #content>
        <div class="container contentRight">
          <div class="row">
            <div class="column"  v-if="this.loaded">
              <img src="@/assets/images/medel-logo.svg" />
              <h1>{{title}}</h1>
              <div class="alert" :class="style">{{message}}</div>
              <logout-button />
            </div>
          </div>
        </div>
      </template>
    </one-column>
    <the-portal-footer />
  </section>
</template>

<script>
import OneColumn from '@/layouts/OneColumn.vue'
import { mapActions } from 'vuex'
import ThePortalFooter from '@/components/ThePortalFooter.vue'
import LogoutButton from '@/components/partials/LogoutButton.vue'

const API_RESPONSE_CODES = {
  SUCCESS: 'success',
  INVALID: 'invalid',
  ERROR: 'error',
  CONFLICT: 'conflict'
}

export default {
  name: 'EmailChangeResult',

  components: {
    OneColumn,
    ThePortalFooter,
    LogoutButton
  },

  metaInfo () {
    return {
      title: 'myMED-EL Email Change Result Page'
    }
  },

  data () {
    return {
      titleKey: '',
      messageKey: '',
      style: '',
      loaded: false
    }
  },

  computed: {
    title () {
      return this.$t(this.getPath(this.titleKey))
    },
    message () {
      return this.$t(this.getPath(this.messageKey))
    },
    loginButtonLabel () {
      return this.$t(this.getPath('loginButtonLabel'))
    }
  },

  async mounted () {
    await this.useCode(this.$route.query.code, this.$route.query.id)
    this.loaded = true
  },

  methods: {
    ...mapActions({
      confirmEmailChange: 'user/confirmEmailChange',
      reloadProfile: 'user/getUserFromDB'
    }),

    async useCode (code, id) {
      if (!code || !id) {
        this.setResultMessage(null)
        return
      }

      try {
        var result = await this.confirmEmailChange({ code, id })

        var resultCode = result.data && result.data.code ? result.data.code : 0
        this.setResultMessage(resultCode)

        // on success reload the user profile
        if (resultCode === API_RESPONSE_CODES.SUCCESS && this.$auth0.isAuthenticated) {
          this.reloadProfile()
        }
      } catch (error) {
        this.setResultMessage(null)
      }
    },

    getPath (key) {
      var translationBasePath = 'pages.emailChangeResult.'
      return translationBasePath + key
    },

    setResultMessage (resultCode) {
      switch (resultCode) {
        case API_RESPONSE_CODES.SUCCESS:
          // 1 indicates that the email was changed successfully
          this.titleKey = 'successTitle'
          this.messageKey = 'successMessage'
          this.style = 'alert-success'
          break
        case API_RESPONSE_CODES.CONFLICT:
          // 2 indicates that the email is already in use
          this.titleKey = 'emailInUseTitle'
          this.messageKey = 'emailInUseMessage'
          this.style = 'alert-conflict'
          break
        case API_RESPONSE_CODES.INVALID:
          // 3 indicates that the token was expired
          this.titleKey = 'tokenExpiredTitle'
          this.messageKey = 'tokenExpiredMessage'
          this.style = 'alert-danger'
          break
        default:
          // any error
          this.titleKey = 'errorTitle'
          this.messageKey = 'errorMessage'
          this.style = 'alert-danger'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.EmailVerifiedPage {
  padding-top: 0px;
  min-height: calc(100vh - 20px);
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-conflict {
  color: #2260e7;
  background-color: #a8c8f7;
  border-color: #3c66a7;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.content {
  display: flex;
  flex-flow: column wrap;
  padding-top: 50px;
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.column {
  display: flex;
  flex-flow: column;
  align-items: center;
}

img {
    width: none;
    flex: 0 1 auto;
    max-width: 200px;
    align-self: center;
  }

.contentRight {
  padding-top: 20px;
  display: flex;
}

</style>
